var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-news"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title primary--text"
  }, [_vm._v("사업소식")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "100%",
      "to": "/campaign/campaign/infant"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/main/visual/visual-mo-1.jpg",
      "aspect-ratio": 768 / 432
    }
  }), _c('div', {
    staticClass: "px-lg-16 py-lg-40"
  }, [_c('v-card-subtitle', {
    staticClass: "pt-lg-0"
  }, [_vm._v("진행중인 캠페인")]), _c('v-card-title', {
    staticClass: "pb-0 text-truncate-2"
  }, [_vm._v(" 2024년 위드캔복지재단 소식지 여름호 "), _c('br'), _vm._v(" [품]:이웃을 안다 ")]), _c('v-card-actions', {
    staticClass: "justify-end pb-lg-0"
  }, [_c('v-btn', {
    staticClass: "px-16 px-md-24",
    attrs: {
      "color": "primary",
      "large": "",
      "rounded": "pill"
    }
  }, [_c('span', {
    staticClass: "font-size-18 font-size-md-20 text-center"
  }, [_vm._v("더 알아보기")]), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "194"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    attrs: {
      "height": "100%",
      "max-width": "40%",
      "src": "/images/test-img.jpg",
      "aspect-ratio": 1
    }
  }), _c('v-sheet', {
    attrs: {
      "max-width": "60%"
    }
  }, [_c('v-card-subtitle', [_vm._v("재단소식")]), _c('v-card-title', [_c('div', {
    staticClass: "text-truncate-3"
  }, [_vm._v("게시글 제목이 옵니다")])])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "194"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    attrs: {
      "height": "100%",
      "max-width": "40%",
      "src": "/images/test-img.jpg",
      "aspect-ratio": 1
    }
  }), _c('v-sheet', {
    attrs: {
      "max-width": "60%"
    }
  }, [_c('v-card-subtitle', [_vm._v("후원자 소식")]), _c('v-card-title', [_c('div', {
    staticClass: "text-truncate-3"
  }, [_vm._v("게시글 제목이 옵니다")])])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "194"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-img', {
    attrs: {
      "height": "100%",
      "max-width": "40%",
      "src": "/images/test-img.jpg",
      "aspect-ratio": 1
    }
  }), _c('v-sheet', {
    attrs: {
      "max-width": "60%"
    }
  }, [_c('v-card-subtitle', [_vm._v("사업기관 소식")]), _c('v-card-title', [_c('div', {
    staticClass: "text-truncate-3"
  }, [_vm._v("게시글 제목이 옵니다")])])], 1)], 1)])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }