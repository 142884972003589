var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-partners"
  }, [_c('v-container', [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(12, function (i, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "rounded-pill",
      attrs: {
        "flat": "",
        "outlined": ""
      }
    }, [_c('img', {
      attrs: {
        "src": "",
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(index + 1) + " ")])], 1);
  }), 0)]), _c('v-btn', {
    staticClass: "swiper-prev",
    attrs: {
      "icon": "",
      "outlined": "",
      "x-large": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-btn', {
    staticClass: "swiper-next",
    attrs: {
      "icon": "",
      "outlined": "",
      "x-large": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }