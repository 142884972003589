<template>
    <section class="main-partners">
        <v-container>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div v-for="(i, index) in 12" :key="index" class="swiper-slide">
                        <v-card flat outlined class="rounded-pill">
                            <img src="" alt="" />
                            {{ index + 1 }}
                        </v-card>
                    </div>
                </div>
            </div>

            <v-btn icon outlined x-large class="swiper-prev">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon outlined x-large class="swiper-next">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-container>
    </section>
</template>

<script>
import Swiper from "swiper/bundle";

export default {
    data() {
        return {
            swiper: undefined,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                this.swiper = new Swiper(".main-partners .swiper-container", {
                    allowTouchMove: true,
                    spaceBetween: 12,
                    slidesPerView: 2,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    navigation: {
                        prevEl: ".main-partners .swiper-prev",
                        nextEl: ".main-partners .swiper-next",
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 6,
                        },
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.main-partners {
    padding: 24px 0;
    border-top: 1px solid #f5f5f5;
    .container {
        position: relative;
    }
    .swiper-prev {
        left: -50px;
        transform: translate(-100%, -50%);
    }
    .swiper-next {
        right: -50px;
        transform: translate(100%, -50%);
    }
}
</style>