<template>
    <section class="main-support primary darken-1">
        <v-container>
            <v-sheet color="transparent" class="main-support__form">
                <v-row justify="end">
                    <v-col cols="12" lg="2">
                        <v-select v-model="defaultOption" block flat solo hide-details :items="options" />
                    </v-col>
                    <v-col cols="12" lg="8">
                        <div class="amounts-wrap">
                            <v-btn-toggle mandatory>
                                <v-btn v-for="amount in amounts" :key="amount">
                                    <span>{{ amount?.format?.() }}원</span>
                                </v-btn>
                                <v-btn @click="focusTextField">
                                    <v-text-field ref="customAmountField" type="number" suffix="원" solo flat hide-details hide-spin-buttons />
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </v-col>
                    <v-col cols="12" lg="2">
                        <v-btn block color="primary" rounded="pill" large class="support-button">
                            후원하기
                            <v-icon>mdi-cursor-default-click-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-container>
        <v-sheet color="grey lighten-5" class="main-support__text">
            <v-container>
                <p class="font-size-14 font-size-md-20 word-keep-all font-weight-medium text-center">
                    <v-row justify="center" class="row--x-small">
                        <v-col cols="auto"> <v-img src="/images/icon/icon-gift.svg" class="icon-gift" /> </v-col>
                        <v-col cols="auto">월 <strong class="primary--text">10,000</strong>원이면, 전 세계 어린이를 위해 책가방 <strong class="primary--text">50개</strong>를 지원할 수 있습니다.</v-col>
                    </v-row>
                </p>
            </v-container>
        </v-sheet>
    </section>
</template>

<script>
export default {
    data() {
        return {
            defaultOption: "regular",
            options: [
                {
                    text: "정기",
                    value: "regular",
                },
                {
                    text: "일시",
                    value: "temporary",
                },
            ],
            amounts: [10000, 30000, 50000],
        };
    },
    methods: {
        focusTextField() {
            this.$nextTick(() => {
                if (this.$refs.customAmountField) {
                    this.$refs.customAmountField.focus();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.main-support {
    &__form {
        min-height: 130px;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__text {
        padding: 12px 0;
        .icon-gift {
            width: 16px;
        }
    }
    .v-select {
        ::v-deep(.v-select__selection) {
            width: 100%;
            margin-right: 0;
            font-size: 18px;
        }
        ::v-deep(.v-input__control) {
            .v-input__slot {
                padding: 0 26px;
            }
        }
        ::v-deep(input) {
            text-align: center;
        }
    }
    .v-input {
        border-radius: 999px;
    }
    .amounts-wrap {
        margin: -2px;
        .v-btn-toggle {
            background-color: transparent;
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            > .v-btn {
                background-color: transparent !important;
                border: none;
                flex-basis: calc(100% / 3);
                padding: 2px;
                opacity: 1;
                &:last-child {
                    cursor: text;
                    flex-basis: 100%;
                }
                &::before {
                    display: none;
                }
                ::v-deep(.v-btn__content) {
                    display: block;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    border: 2px solid #fff;
                    border-radius: 999px;
                    max-width: 100%;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                }
                .v-input {
                    height: 100%;
                    &::before {
                        content: "금액 직접 입력";
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        z-index: 1;
                    }
                    ::v-deep {
                        .v-input__control,
                        .v-input__slot {
                            height: 100%;
                            background-color: transparent;
                            color: var(--v-primary-darken1);
                        }
                    }
                    ::v-deep(input) {
                        text-align: center;
                        font-size: 20px;
                        color: var(--v-primary-darken1);
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                        }
                    }
                }
                &--active {
                    ::v-deep(.v-btn__content) {
                        background-color: #fff;
                        color: var(--v-primary-base);
                    }
                    .v-input {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .support-button {
        font-size: 20px;
        box-shadow: 0 4px 33.8px 0 rgba(0, 0, 0, 0.25);
        .v-icon {
            font-size: 20px;
            margin-left: 10px;
        }
    }
}
@media (min-width: 1024px) {
    .main-support {
        &__text {
            .icon-gift {
                width: 24px;
            }
        }
        .v-select {
            width: 140px;
        }
        .v-input {
            border-radius: 999px;
        }
        .amounts-wrap {
            margin: 0 -6px;
            .v-btn-toggle {
                > .v-btn {
                    padding: 0 6px;
                    flex-basis: 25%;
                    ::v-deep(.v-btn__content) {
                        height: 48px;
                    }
                    &:last-child {
                        flex-basis: 25%;
                    }
                    ::v-deep(.v-btn__content) {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
</style>