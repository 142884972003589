var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-boards"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "main-boards__list"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('main-notice')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('main-coverage')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "border-radius-16 pa-24 pa-lg-40",
    attrs: {
      "color": "primary darken-2",
      "flat": ""
    }
  }, [_c('h3', {
    staticClass: "tit tit--sm white--text pb-18 pb-lg-24"
  }, [_vm._v("뉴스레터 신청하기")]), _c('div', {
    staticClass: "pb-18 pb-lg-24"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('v-text-field', {
    staticClass: "rounded-pill",
    attrs: {
      "flat": "",
      "placeholder": "이름",
      "solo": "",
      "color": "white",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('v-text-field', {
    staticClass: "rounded-pill",
    attrs: {
      "flat": "",
      "placeholder": "메일아이디",
      "solo": "",
      "color": "white",
      "hide-details": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-size-20 white--text"
  }, [_vm._v("@")])]), _c('v-col', [_c('v-combobox', {
    staticClass: "rounded-pill",
    attrs: {
      "flat": "",
      "placeholder": "메일주소  |  직접 입력",
      "solo": "",
      "color": "white",
      "hide-details": ""
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('v-btn', {
    staticClass: "support-button",
    attrs: {
      "block": "",
      "color": "primary",
      "rounded": "pill",
      "large": ""
    }
  }, [_vm._v(" 신청하기 "), _c('v-icon', [_vm._v("mdi-cursor-default-click-outline")])], 1)], 1)], 1)], 1), _c('div', [_c('v-sheet', {
    attrs: {
      "color": "white",
      "height": "1"
    }
  }), _c('div', {
    staticClass: "px-8 py-12"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text white--text"
  }, [_vm._v("개인정보 수집 및 이용에 동의하십니까?")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center cursor-pointer"
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "id": "agree",
      "color": "white"
    }
  }), _c('label', {
    staticClass: "cursor-pointer",
    attrs: {
      "for": "agree"
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v("동의함")])]), _c('router-link', {
    staticClass: "white--text text-decoration-underline ml-10",
    attrs: {
      "to": "/privacy-policy",
      "target": "_blank"
    }
  }, [_vm._v("전문보기")])], 1)])], 1)], 1), _c('v-sheet', {
    attrs: {
      "color": "white",
      "height": "1"
    }
  })], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }