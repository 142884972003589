<template>
    <div class="main-board">
        <v-sheet color="grey lighten-5" class="main-board__head d-flex justify-space-between align-center rounded-pill px-16 py-8">
            <v-img max-width="28" src="/images/icon/icon-notice.svg" class="mr-8" />
            <h4 class="tit tit--sm line-height-1 primary--text">공지사항</h4>
            <router-link to="community/board?code=notice" class="text-btn">
                <span>자세히보기</span>
                <i class="icon icon-right-arrow-black ml-2 ml-lg-4"></i>
            </router-link>
        </v-sheet>
        <div class="main-board__contents px-16">
            <!-- S::위드 캔 소식 -->
            <div v-for="(board, idx) in notice" :key="idx" class="main-board__row pa-8 py-md-20" @click="search(board._id)">
                <v-row align="center">
                    <v-col cols="9" md="10">
                        <p class="page-text page-text--lg grey-1--text ellip">
                            {{ board.subject }}
                        </p>
                    </v-col>
                    <v-spacer />
                    <v-col cols="3" md="2">
                        <p class="font-size-12 font-size-lg-14 grey-9--text text-end">
                            {{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}
                        </p>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            notice: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var noticeList = await api.v1.boards.gets({
                headers: {
                    limit: 4,
                },
                params: { code: "notice" },
            });
            this.notice = noticeList.boards;
        },
        search(_board) {
            this.$router.push(`community/${_board}`);
        },
    },
};
</script>