<template>
    <section class="main-news">
        <v-container>
            <div class="section-title-wrap" data-aos="fade-left">
                <h2 class="section-title primary--text">사업소식</h2>
            </div>

            <v-row>
                <v-col cols="12" lg="7">
                    <v-card height="100%" to="/campaign/campaign/infant">
                        <v-img src="/images/main/visual/visual-mo-1.jpg" :aspect-ratio="768 / 432" />
                        <div class="px-lg-16 py-lg-40">
                            <v-card-subtitle class="pt-lg-0">진행중인 캠페인</v-card-subtitle>
                            <v-card-title class="pb-0 text-truncate-2">
                                2024년 위드캔복지재단 소식지 여름호 <br />
                                [품]:이웃을 안다
                            </v-card-title>
                            <v-card-actions class="justify-end pb-lg-0">
                                <v-btn color="primary" large rounded="pill" class="px-16 px-md-24">
                                    <span class="font-size-18 font-size-md-20 text-center">더 알아보기</span>
                                    <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="5">
                    <v-row>
                        <v-col cols="12">
                            <v-card max-height="194">
                                <div class="d-flex align-center">
                                    <v-img height="100%" max-width="40%" src="/images/test-img.jpg" :aspect-ratio="1" />
                                    <v-sheet max-width="60%">
                                        <v-card-subtitle>재단소식</v-card-subtitle>
                                        <v-card-title>
                                            <div class="text-truncate-3">게시글 제목이 옵니다</div>
                                        </v-card-title>
                                    </v-sheet>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card max-height="194">
                                <div class="d-flex align-center">
                                    <v-img height="100%" max-width="40%" src="/images/test-img.jpg" :aspect-ratio="1" />
                                    <v-sheet max-width="60%">
                                        <v-card-subtitle>후원자 소식</v-card-subtitle>
                                        <v-card-title>
                                            <div class="text-truncate-3">게시글 제목이 옵니다</div>
                                        </v-card-title>
                                    </v-sheet>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card max-height="194">
                                <div class="d-flex align-center">
                                    <v-img height="100%" max-width="40%" src="/images/test-img.jpg" :aspect-ratio="1" />
                                    <v-sheet max-width="60%">
                                        <v-card-subtitle>사업기관 소식</v-card-subtitle>
                                        <v-card-title>
                                            <div class="text-truncate-3">게시글 제목이 옵니다</div>
                                        </v-card-title>
                                    </v-sheet>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main-news {
    padding: 60px 0 40px;
}
.v-card {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 24px 0 rgba(133, 149, 168, 0.25) !important;
    &__subtitle,
    &__title,
    &__actions {
        padding-left: 24px;
        padding-right: 24px;
    }
    &__subtitle {
        color: var(--v-primary-base) !important;
        padding-bottom: 8px;
        font-size: var(--title-font-size-xs);
    }
    &__title {
        padding-top: 0;
        font-size: var(--title-font-size);
        line-height: 1.35;
        font-weight: 500;
        word-break: keep-all;
    }
}
@media (min-width: 1024px) {
    .main-news {
        padding: 120px 0 80px;
    }
}
</style>