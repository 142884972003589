var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('main-visual'), _c('main-support'), _c('main-news'), _c('main-image-banner'), _c('main-transparency'), _c('main-influence'), _c('main-boards'), _c('main-partners')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }