var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-support primary darken-1"
  }, [_c('v-container', [_c('v-sheet', {
    staticClass: "main-support__form",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('v-select', {
    attrs: {
      "block": "",
      "flat": "",
      "solo": "",
      "hide-details": "",
      "items": _vm.options
    },
    model: {
      value: _vm.defaultOption,
      callback: function ($$v) {
        _vm.defaultOption = $$v;
      },
      expression: "defaultOption"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "amounts-wrap"
  }, [_c('v-btn-toggle', {
    attrs: {
      "mandatory": ""
    }
  }, [_vm._l(_vm.amounts, function (amount) {
    var _amount$format;
    return _c('v-btn', {
      key: amount
    }, [_c('span', [_vm._v(_vm._s(amount === null || amount === void 0 ? void 0 : (_amount$format = amount.format) === null || _amount$format === void 0 ? void 0 : _amount$format.call(amount)) + "원")])]);
  }), _c('v-btn', {
    on: {
      "click": _vm.focusTextField
    }
  }, [_c('v-text-field', {
    ref: "customAmountField",
    attrs: {
      "type": "number",
      "suffix": "원",
      "solo": "",
      "flat": "",
      "hide-details": "",
      "hide-spin-buttons": ""
    }
  })], 1)], 2)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_c('v-btn', {
    staticClass: "support-button",
    attrs: {
      "block": "",
      "color": "primary",
      "rounded": "pill",
      "large": ""
    }
  }, [_vm._v(" 후원하기 "), _c('v-icon', [_vm._v("mdi-cursor-default-click-outline")])], 1)], 1)], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "main-support__text",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-container', [_c('p', {
    staticClass: "font-size-14 font-size-md-20 word-keep-all font-weight-medium text-center"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "icon-gift",
    attrs: {
      "src": "/images/icon/icon-gift.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("월 "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("10,000")]), _vm._v("원이면, 전 세계 어린이를 위해 책가방 "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("50개")]), _vm._v("를 지원할 수 있습니다.")])], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }