var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-visual"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "d-lg-none",
      attrs: {
        "img": item.mo,
        "to": item.to,
        "tile": "",
        "flat": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 768 / 432
      }
    }, [_c('span', {
      staticClass: "d-sr-only"
    }, [_vm._v(" " + _vm._s(item.sl) + " ")])])], 1), _c('v-card', {
      staticClass: "d-none d-lg-block",
      attrs: {
        "img": item.pc,
        "to": item.to,
        "tile": "",
        "flat": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1920 / 720
      }
    }, [_c('span', {
      staticClass: "d-sr-only"
    }, [_vm._v(" " + _vm._s(item.sl) + " ")])])], 1)], 1);
  }), 0), _vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "swiper-control"
  }, [_c('div', {
    staticClass: "swiper-prev",
    attrs: {
      "aria-label": "이전 슬라이드"
    }
  }), _c('div', {
    staticClass: "swiper-pagination",
    attrs: {
      "aria-label": "슬라이드 페이지네이션"
    }
  }), _c('div', {
    staticClass: "swiper-next",
    attrs: {
      "aria-label": "다음 슬라이드"
    }
  })]);

}]

export { render, staticRenderFns }