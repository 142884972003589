<template>
    <section class="main-transparency">
        <v-sheet color="#FFEFB4">
            <v-container>
                <v-row justify="center" align="center" no-gutters>
                    <v-col cols="12" xl="" class="pt-40 pt-lg-0">
                        <div class="tit-wrap">
                            <h2 class="client-title--xl font-weight-regular" data-aos="fade-up">
                                사회복지 실천 전문법인으로 <br class="d-none d-sm-block" />
                                <strong class="primary--text">위드-캔복지재단</strong>은 <strong class="primary--text">투명</strong>합니다.
                            </h2>
                            <p class="client-title--xs mt-16 line-height-15" data-aos="fade-up" data-aos-delay="200">
                                위드-캔복지재단은 투명성, 전문성, 변화와 도전, 인간존중, 사회연대의 <br class="d-none d-sm-block" />
                                핵심가치를 바탕으로 운영됩니다.
                            </p>
                        </div>
                        <v-btn color="primary" outlined large rounded="pill"  data-aos="zoom-in-up" data-aos-delay="400">
                            <div class="d-flex align-center">
                                2023 재정보고 바로가기
                                <v-icon small class="pl-4">mdi-chevron-right</v-icon>
                            </div>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" xl="6">
                        <v-img max-width="658" src="/images/main/transparency/family.jpg" alt="" class="mx-auto" />
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main-transparency {
    text-align: center;
    .v-btn {
        border-width: 2px;
        padding: 0 18px;
    }
}
@media (min-width: 768px) {
    .main-transparency {
        .v-btn {
            padding: 0 24px;
        }
    }
}

@media (min-width: 1200px) {
    .main-transparency {
        text-align: start;
    }
}
</style>