var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-transparency"
  }, [_c('v-sheet', {
    attrs: {
      "color": "#FFEFB4"
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pt-40 pt-lg-0",
    attrs: {
      "cols": "12",
      "xl": ""
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "client-title--xl font-weight-regular",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 사회복지 실천 전문법인으로 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("위드-캔복지재단")]), _vm._v("은 "), _c('strong', {
    staticClass: "primary--text"
  }, [_vm._v("투명")]), _vm._v("합니다. ")]), _c('p', {
    staticClass: "client-title--xs mt-16 line-height-15",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" 위드-캔복지재단은 투명성, 전문성, 변화와 도전, 인간존중, 사회연대의 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 핵심가치를 바탕으로 운영됩니다. ")])]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "large": "",
      "rounded": "pill",
      "data-aos": "zoom-in-up",
      "data-aos-delay": "400"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_vm._v(" 2023 재정보고 바로가기 "), _c('v-icon', {
    staticClass: "pl-4",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "658",
      "src": "/images/main/transparency/family.jpg",
      "alt": ""
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }