<template>
    <section class="main-boards">
        <v-container>
            <v-row class="main-boards__list">
                <v-col cols="12" lg="6">
                    <main-notice />
                </v-col>
                <v-col cols="12" lg="6">
                    <main-coverage />
                </v-col>
                <v-col cols="12">
                    <v-card color="primary darken-2" flat class="border-radius-16 pa-24 pa-lg-40">
                        <h3 class="tit tit--sm white--text pb-18 pb-lg-24">뉴스레터 신청하기</h3>
                        <div class="pb-18 pb-lg-24">
                            <v-row>
                                <v-col cols="12" lg="2">
                                    <v-text-field flat placeholder="이름" solo color="white" hide-details class="rounded-pill"></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="8">
                                    <v-row align="center" class="row--xs">
                                        <v-col lg="3">
                                            <v-text-field flat placeholder="메일아이디" solo color="white" hide-details class="rounded-pill"></v-text-field>
                                        </v-col>
                                        <v-col cols="auto">
                                            <span class="font-size-20 white--text">@</span>
                                        </v-col>
                                        <v-col>
                                            <v-combobox flat placeholder="메일주소  |  직접 입력" solo color="white" hide-details class="rounded-pill" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" lg="2">
                                    <v-btn block color="primary" rounded="pill" large class="support-button">
                                        신청하기
                                        <v-icon>mdi-cursor-default-click-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>

                        <div>
                            <v-sheet color="white" height="1" />
                            <div class="px-8 py-12">
                                <v-row>
                                    <v-col cols="auto">
                                        <p class="page-text white--text">개인정보 수집 및 이용에 동의하십니까?</p>
                                    </v-col>
                                    <v-col cols="auto">
                                        <div class="d-flex align-center cursor-pointer">
                                            <v-checkbox hide-details id="agree" color="white"></v-checkbox>
                                            <label for="agree" class="cursor-pointer">
                                                <span class="white--text">동의함</span>
                                            </label>
                                            <router-link to="/privacy-policy" target="_blank" class="white--text text-decoration-underline ml-10">전문보기</router-link>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-sheet color="white" height="1" />
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import MainNotice from "./main-boards/main-notice.vue";
import MainCoverage from "./main-boards/main-coverage.vue";
export default {
    components: {
        MainNotice,
        MainCoverage,
    },
};
</script>

<style lang="scss" scoped>
.main-boards {
    padding: 50px 0;
    &__list {
        margin: -16px;
        > [class*="col"] {
            padding: 16px;
        }
    }
}
@media (min-width: 768px) {
    .main-boards {
        padding: 100px 0;
        &__list {
            margin: -20px;
            > [class*="col"] {
                padding: 20px;
            }
        }
    }
}
</style>