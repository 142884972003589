var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-image-banner"
  }, [_c('v-container', [_c('v-card', {
    staticClass: "primary lighten-5",
    attrs: {
      "tile": "",
      "flat": "",
      "img": _vm.item.image,
      "to": _vm.item.route
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1200 / 152
    }
  }, [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._v(" " + _vm._s(_vm.item.texts) + " ")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }