var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-board"
  }, [_c('v-sheet', {
    staticClass: "main-board__head d-flex justify-space-between align-center rounded-pill px-12 px-md-16 py-8",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-img', {
    staticClass: "mr-8",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-coverage.svg"
    }
  }), _c('h4', {
    staticClass: "tit tit--sm line-height-1 primary--text"
  }, [_vm._v("언론보도")]), _c('router-link', {
    staticClass: "text-btn",
    attrs: {
      "to": "community/board?code=notice"
    }
  }, [_c('span', [_vm._v("자세히보기")]), _c('i', {
    staticClass: "icon icon-right-arrow-black ml-2 ml-lg-4"
  })])], 1), _c('div', {
    staticClass: "main-board__contents px-12 px-md-16"
  }, _vm._l(_vm.notice, function (board, idx) {
    return _c('div', {
      key: idx,
      staticClass: "main-board__row pa-8 py-md-20",
      on: {
        "click": function ($event) {
          return _vm.search(board._id);
        }
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('p', {
      staticClass: "page-text page-text--lg grey-1--text ellip"
    }, [_vm._v(" " + _vm._s(board.subject) + " ")])]), _c('v-spacer'), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('p', {
      staticClass: "font-size-12 font-size-lg-14 grey-9--text"
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(board.createdAt).format("YYYY.MM.DD")) + " ")])])], 1)], 1);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }