var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "main-links main-section primary"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap"
  }, [_c('h2', {
    staticClass: "section-title white--text font-weight-regular",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('strong', [_vm._v("나눔,")]), _vm._v(" 함께 만드는 "), _c('strong', [_vm._v("선한 영향력")])])]), _c('ul', {
    staticClass: "links-list"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('li', {
      key: index
    }, [_c('v-card', {
      attrs: {
        "rounded": "xl",
        "flat": "",
        "data-aos": "fade-left",
        "data-aos-delay": index * 100
      }
    }, [_c('v-responsive', {
      staticClass: "d-flex justify-center align-center",
      attrs: {
        "aspect-ratio": 1
      }
    }, [_c('div', {
      staticClass: "pa-3"
    }, [_c('v-img', {
      staticClass: "mx-auto",
      attrs: {
        "src": item.icon,
        "aspect-ratio": 1,
        "max-width": "50"
      }
    }), _c('v-card-title', {
      staticClass: "justify-center page-text grey-3--text font-weight-regular pb-0 px-0"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)])], 1)], 1);
  }), 0)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }