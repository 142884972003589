<template>
    <section class="main-links main-section primary">
        <v-container>
            <div class="section-title-wrap">
                <h2 class="section-title white--text font-weight-regular" data-aos="fade-left"><strong>나눔,</strong> 함께 만드는 <strong>선한 영향력</strong></h2>
            </div>

            <ul class="links-list">
                <li v-for="(item, index) in items" :key="index">
                    <v-card rounded="xl" flat data-aos="fade-left" :data-aos-delay="index * 100">
                        <v-responsive :aspect-ratio="1" class="d-flex justify-center align-center">
                            <div class="pa-3">
                                <v-img :src="item.icon" :aspect-ratio="1" max-width="50" class="mx-auto" />
                                <v-card-title class="justify-center page-text grey-3--text font-weight-regular pb-0 px-0">
                                    {{ item.name }}
                                </v-card-title>
                            </div>
                        </v-responsive>
                    </v-card>
                </li>
            </ul>
        </v-container>
    </section>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    icon: "/images/main/influence/icon-influence-1.svg",
                    name: "정기후원",
                },
                {
                    icon: "/images/main/influence/icon-influence-2.svg",
                    name: "일시후원",
                },
                {
                    icon: "/images/main/influence/icon-influence-3.svg",
                    name: "국내아동결연",
                },
                {
                    icon: "/images/main/influence/icon-influence-4.svg",
                    name: "해외아동결연",
                },
                {
                    icon: "/images/main/influence/icon-influence-5.svg",
                    name: "장애아동후원",
                },
                {
                    icon: "/images/main/influence/icon-influence-6.svg",
                    name: "독거노인후원",
                },
                {
                    icon: "/images/main/influence/icon-influence-7.svg",
                    name: "위드캔아너클럽",
                },
                {
                    icon: "/images/main/influence/icon-influence-8.svg",
                    name: "유산기부",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.main-links {
    .links-list {
        display: flex;
        flex-wrap: wrap;
        margin: -8px auto;
        max-width: calc(136px * 2 + 8px * 2);
        > li {
            flex-basis: 50%;
            padding: 8px;

            .v-card {
                max-width: 136px;
                margin: 0 auto;
            }
        }
    }
}
@media (min-width: 768px) {
    .main-links {
        .section-title-wrap {
            margin-bottom: 60px;
        }
        .links-list {
            max-width: calc(136px * 4 + 8px * 2);
            > li {
                flex-basis: 25%;
            }
        }
    }
}
@media (min-width: 1200px) {
    .main-links {
        .links-list {
            max-width: initial;
            margin: -8px;
            > li {
                flex-basis: calc(100% / 8);
                padding: 8px;
            }
        }
    }
}
</style>