<template>
    <section class="main-visual">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="(item, index) in items" :key="index" class="swiper-slide">
                    <v-card :img="item.mo" :to="item.to" tile flat class="d-lg-none">
                        <v-responsive :aspect-ratio="768 / 432">
                            <span class="d-sr-only">
                                {{ item.sl }}
                            </span>
                        </v-responsive>
                    </v-card>

                    <v-card :img="item.pc" :to="item.to" tile flat class="d-none d-lg-block">
                        <v-responsive :aspect-ratio="1920 / 720">
                            <span class="d-sr-only">
                                {{ item.sl }}
                            </span>
                        </v-responsive>
                    </v-card>
                </div>
            </div>
            <div class="swiper-control">
                <div class="swiper-prev" aria-label="이전 슬라이드" />
                <div class="swiper-pagination" aria-label="슬라이드 페이지네이션"></div>
                <div class="swiper-next" aria-label="다음 슬라이드" />
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from "swiper/bundle";

export default {
    data() {
        return {
            swiper: undefined,

            items: [
                {
                    mo: "/images/main/visual/visual-mo-1.jpg",
                    pc: "/images/main/visual/visual-1.jpg",
                    sl: "태어나자마자 혼자가 된 아이들. 세상을 바꾸는 일. #With You, #With Can 지금, 여러분 따뜻한 품으로 안아주세요",
                    to: "/campaign/campaign/infant"
                },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                this.swiper = new Swiper(".main-visual .swiper-container", {
                    effect: "fade",
                    allowTouchMove: true,
                    centeredSlides: true,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 500,
                    loop: true,
                    pagination: {
                        el: ".main-visual .swiper-pagination",
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".main-visual .swiper-next",
                        prevEl: ".main-visual .swiper-prev",
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    .swiper-control {
        height: 100%;
        .swiper-prev,
        .swiper-next {
            aspect-ratio: 35 / 65;
            width: 16px;
            height: auto;
            background-repeat: no-repeat;
            background-position: top;
            background-color: transparent;
            background-size: 100%;
        }
        .swiper-prev {
            background-image: url(/images/main/visual/swiper-prev.svg);
        }
        .swiper-next {
            background-image: url(/images/main/visual/swiper-next.svg);
        }
        ::v-deep(.swiper-pagination) {
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            .swiper-pagination-bullet {
                background-color: #fff;
                opacity: 0.4;
                &-active {
                    opacity: 1;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    // main-visual
    .main-visual {
        .swiper-control {
            .swiper-prev,
            .swiper-next {
                width: 35px;
            }
            ::v-deep(.swiper-pagination) {
                bottom: 40px;
            }
        }
    }
}
</style>
