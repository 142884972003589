<template>
    <section class="main-image-banner">
        <v-container>
            <v-card tile flat :img="item.image" :to="item.route" class="primary lighten-5">
                <v-responsive :aspect-ratio="1200 / 152">
                    <span class="d-sr-only">
                        {{ item.texts }}
                    </span>
                </v-responsive>
            </v-card>
        </v-container>
    </section>
</template>

<script>
export default {
    data() {
        return {
            item: {
                image: "",
                route: "",
                texts: "",
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.main-image-banner {
    padding-bottom: 60px;
}
@media (min-width: 1024px) {
    .main-image-banner {
        padding-bottom: 120px;
    }
}
</style>